import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import * as Sentry from '@sentry/browser';
import * as serviceWorker from './serviceWorker';
import { bootstrapApp } from '@getclarauk/ui/dist/util/bootstrapApp';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://cc279cd647f342f79a8bd627475856f0@sentry.io/1893442',

    beforeSend: (event, hint) => {
      return (hint?.originalException as any)?.noSentry ? null : event;
    },
  });
}

bootstrapApp(() => import('./MyClaraApp'));

// if (serviceWorker.isLocalhost) {
serviceWorker.unregister();
// } else {
// serviceWorker.register();
// }
